/*eslint-disable */
// import SpcmSessionModel from '../../Model/SpcmSession'
import request from '../../Utils/curl'
import Utility from '../../Utils/utility';

let SpcmSessions = {
  /**
   * spcmSessionList
   */
  async spcmSessionList (context, spcmObj) {
    try{
      let post_data = new FormData();  
      if (spcmObj) {
        for (let item in spcmObj) {
          if (spcmObj[item] || typeof spcmObj[item] == 'number') {
            post_data.append(item, spcmObj[item])
          }
        }
      }

      return await request.curl(context, "spcm_session_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmSessionList() and Exception:',err.message)
    }
  },

  /**
   * spcmSessionConsoleList
   */
  async spcmSessionConsoleList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_session_console_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmSessionConsoleList() and Exception:',err.message)
    }
  },

  /**
   * spcmSessionView
   */
  async spcmSessionView (context, spcmSessionId) {
    try {      
      let post_data = new FormData();
      post_data.append('session_id', spcmSessionId);
      return await request.curl(context, "spcm_session_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at spcmSessionView() and Exception:",err.message)
    }    
  },

  /**
   * spcmSessionAdd
   */
  async spcmSessionAdd (context, spcmSessionObj) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please Wait"
    try{
      let post_data = new FormData();
    
      for (let key in spcmSessionObj) {
        if (spcmSessionObj[key] || typeof spcmSessionObj[key] == 'number') {
          post_data.append(key, spcmSessionObj[key]);
        }
      }

      return await request.curl(context, "spcm_session_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmSessionAdd() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  /**
   * spcmSessionEdit
   */
  async spcmSessionEdit (context, spcmSessionObj) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please Wait"
    try {
      let post_data = new FormData();
    
      for (let key in spcmSessionObj) {
        if (spcmSessionObj[key] || typeof spcmSessionObj[key] == 'number') {
          post_data.append(key, spcmSessionObj[key]);
        }
      }

      return await request.curl(context, "spcm_session_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcmSessionEdit() and Exception:',err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },

  /**
   * spcmSessionDelete
   */
  async spcmSessionDelete (context, spcmSessionId) {
    try{
      let post_data = new FormData();
      
      post_data.append('session_id', spcmSessionId);

      return await request.curl(context, "spcm_session_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcmSessionDelete() and Exception:',err.message)
    }
  }
}

export {
  SpcmSessions
}
