<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmSPCMFireFliesFormData && Object.keys(vmSPCMFireFliesFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationmeeting_id">{{cvMeetingIdLabel}}</label>
                  <input v-model="vmSPCMFireFliesFormData.meeting_id" type="text" class="form-control" required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationtitle">{{cvTitleLabel}}</label>
                  <input v-model="vmSPCMFireFliesFormData.title" type="text" class="form-control" required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcounselor_id">{{cvCounselorIdLabel}}</label>
                  <input v-model="vmSPCMFireFliesFormData.counselor_id" type="text" class="form-control" required/>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationmeeting_attendees">{{cvMeetingAttendeesLabel}}</label>
                  <vue-editor v-model="vmSPCMFireFliesFormData.meeting_attendees"></vue-editor>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validation_overview">{{cvOverviewLabel}}</label>
                  <vue-editor v-model="vmSPCMFireFliesFormData.overview"></vue-editor>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validation_gist">{{cvGistLabel}}</label>
                  <vue-editor v-model="vmSPCMFireFliesFormData.gist"></vue-editor>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validation_bullet_gist">{{cvBulletGistLabel}}</label>
                  <vue-editor v-model="vmSPCMFireFliesFormData.bullet_gist"></vue-editor>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationkeywords">{{cvKeywordsLabel}}</label>
                  <vue-editor v-model="vmSPCMFireFliesFormData.keywords"></vue-editor>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationsummary">{{cvActionItemsLabel}}</label>
                  <vue-editor v-model="vmSPCMFireFliesFormData.action_items"></vue-editor>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="SPCMFireFliesEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { SPCMFireFlies } from "../../../FackApi/api/SPCMFireflies"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import { VueEditor } from "vue2-editor"

export default {
  name: "SPCMFireFliesEdit",
  components: {
    VueEditor
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSPCMFireFliesObj: {
      type: Object,
      default: function () {
        return {
          "meeting_id": "",
          "counselor_id": "",
          "title": "",
          "gist": "",
          "bullet_gist": "",
          "overview": "",
          "action_items": "",
          "keywords": "",
          "meeting_attendees": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit spcm FireFile",
      cvCardSubHeader: "Edit spcm FireFile sub header",
      cvSubmitBtn: "Edit",
      cvMeetingIdLabel: "meeting id",
      cvCounselorIdLabel: "counselor id",
      cvTitleLabel: "title",
      cvGistLabel: "Gist",
      cvKeywordsLabel: "keywords",
      cvBulletGistLabel: "Bullet Gist",
      cvOverviewLabel: "Overview",
      cvActionItemsLabel: "Action Items",
      cvMeetingAttendeesLabel: "meeting attendees",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "SPCMFireFlies Updated",
      vmSPCMFireFliesFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.spcmFireFliesView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSPCMFireFliesFormData) {
          if (!this.vmSPCMFireFliesFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * spcmFireFliesView
     */
    async spcmFireFliesView () {
      try {
        if (this.propOpenedInModal) {
          this.vmSPCMFireFliesFormData = this.propSPCMFireFliesObj
        }
        else {
          let SPCMFireFliesId = this.$route.params.spcmff_id
          let SPCMFireFliesViewResp = await SPCMFireFlies.spcmFireFliesView(this, SPCMFireFliesId)
          if (SPCMFireFliesViewResp && SPCMFireFliesViewResp.resp_status) {
            this.vmSPCMFireFliesFormData = SPCMFireFliesViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmFireFliesView() and Exception:", err.message)
      }
    },
    /**
     * SPCMFireFliesEdit
     */
    async SPCMFireFliesEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let SPCMFireFliesAddResp = await SPCMFireFlies.SPCMFireFliesEdit(this, this.vmSPCMFireFliesFormData)
        await ApiResponse.responseMessageDisplay(this, SPCMFireFliesAddResp)

        if (SPCMFireFliesAddResp && !SPCMFireFliesAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSPCMFireFliesEditModal", this.vmSPCMFireFliesFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at SPCMFireFliesEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
